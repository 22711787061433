import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

// export const config = new anchor.web3.PublicKey(
//   "DNz1LYRD8pVK21qJDivh96ZHqzWYk94rnR3TrXcvK56m"
// );
// export const configUUID = "DNz1LY";
export const config = new anchor.web3.PublicKey(
  "EdMkMvdwaC4a4KhwLJn9jERwswRYTuiWbHeJ1HFejHRZ"
);
export const configUUID = "EdMkMv";

// export const candyMachineOwner = new anchor.web3.PublicKey(
//   "AUaeHCdYuj7x8EbNrd9gDrZ6vWbKNbrXpnzwzugeZG53"
// );
export const candyMachineOwner = new anchor.web3.PublicKey(
  "gm1okUw8S1jRhtT2A4ZMHucUfSCc7Sp3MPvUo5hcic9"
);
