import React from "react";
import "./App.css";
import { WalletProvider, useWallet } from "./providers/useWallet";
import { mintNFT } from "./actions/mintNFT";
import { CandyMachineProvider, useCandyMachine } from "./providers/useCandyMachine";

function App() {
  return (
    <div className="App">
      <WalletProvider>
        <CandyMachineProvider>
          <BuyScreen />
        </CandyMachineProvider>
      </WalletProvider>
    </div>
  );
}

function BuyScreen() {
  const { connection, wallet, connectWallet } = useWallet();
  const { candyMachine, findCandyMachine } = useCandyMachine();
  let displayRemaining = "";
  if (connection && wallet && !candyMachine) {
    findCandyMachine(connection, wallet);
    console.log(candyMachine);
  }
  if (candyMachine) {
    displayRemaining =
      (10000 - candyMachine.itemsRedeemed.toNumber()).toString() + "/10000";
  }

  return (
    <header className="App-header">
      <div className="Container">
        <h1>gm</h1>

        <div className="grid2x2">
          <p>minting for say gm is now closed</p>
          <p>visit&nbsp;<a href="https://bit.ly/saygmnft">discord</a>&nbsp;or&nbsp;<a href="https://twitter.com/saygmnft">twitter</a>&nbsp;for more info</p>
        </div>
      </div>
    </header>
  );
}
export default App;
